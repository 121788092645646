import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

import { sendDatebarScroll } from '../../services/Analytics';

const CHILDREN_TO_IGNORE = 3;

export const useHorizontalScrollDirection = (ref: RefObject<HTMLDivElement>) => {
  const lastPosition = useRef(0);

  const onScrollEnd = useCallback((): void => {
    const currentPosition = ref.current?.scrollLeft || 0;
    const firstChildWidth = ref.current?.children[0].clientWidth || 0;

    // not send on small scroll
    if (Math.abs(currentPosition - lastPosition.current) < firstChildWidth * CHILDREN_TO_IGNORE) {
      lastPosition.current = currentPosition;
      return;
    }

    const direction = lastPosition.current <= currentPosition ? 'right' : 'left';
    lastPosition.current = currentPosition;
    sendDatebarScroll(direction);
  }, [ref]);

  const debouncedScroll = useMemo(() => debounce(() => {
    onScrollEnd();
  }, 500), [onScrollEnd]);

  useEffect(() => {
    const currentDatebarRefValue = ref.current;
    currentDatebarRefValue?.addEventListener('scrollend', debouncedScroll);

    return (): void => {
      currentDatebarRefValue?.removeEventListener('scrollend', debouncedScroll);
    };
  }, [ref, debouncedScroll]);
};
